import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import "../sass/main.scss"
import "./font-face.css"
import Footer from "./footer/footer"
import Header from "./header/header"
import "./layout.css"
import { useStaticQuery, graphql } from "gatsby"

//hooks
import useWindowSize from "./utils/useWindowsSize"

if (typeof window !== "undefined") {
  if (window.fbq != null) {
    window.fbq("track", "PageView")
  }
}

const LayoutSolution = ({ children, banner, location }) => {
  // const size = useWindowSize()
  useEffect(() => {
    // localStorage.setItem("title", "")
    if (location.pathname !== "/contact") localStorage.removeItem("title")
  })

  const data = useStaticQuery(graphql`
    query {
      allSanityCaseStudy(
        sort: { fields: [publishedAt], order: DESC }
        limit: 3
      ) {
        edges {
          node {
            title
            publishedAt(formatString: "MMMM DD, YYYY")
            mainImage {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  width: 580
                )
              }
            }
            bannerImage {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  width: 580
                )
              }
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const dataSrc = data?.allSanityCaseStudy

  console.log("dataSrcsss", dataSrc)

  return (
    <>
      <Header location={location} dataSrc={dataSrc} />
      {banner}
      <main>{children}</main>
      <Footer />
    </>
  )
}

LayoutSolution.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutSolution
